import React, { useRef, useState } from "react";
import { Amplify } from "aws-amplify";
import { Storage } from "@aws-amplify/storage";
import {v4 as uuid} from "uuid";
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";

import awsconfig from "../constants/amplifyConfiguration"; 



Amplify.configure(awsconfig);


export default function UploadFiles() {
  const ref = useRef()
  const [ fileToUpload, setFileToUpload ] = useState();
  const [ uploadStatus, setUploadStatus ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ isFlashbarDisplayed, setIsFlashbarDisplayed ] = useState(false);
  const [ flashbarItems, setFlashbarItems ] = useState([
    {
      type: "success",
      content: "The file has been successfully uploaded to the translation app. Your file is being translated. The translated file will automatically be downloaded shortly, please wait (up to 2 minutes). ",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => {
        setIsFlashbarDisplayed(false);
        getFile();
      },
      id: "message_1"
    }
  ]);
    // To upload files using Amplify

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
  
  // usage
  async function getFile(key) {
    const success = false;
    try {
      const result = await Storage.get(key, { download: true });
      downloadBlob(result.Body, key.split('/')[2]);
    } catch (error) {
      if(error.message === "NoSuchKey"){
        console.log("Caught the NoSuchKey error");
        console.log("Trying again in 10 seconds");
        setTimeout(getFile, 10*1000, key);
      }
    }   
  }
  function handleClick(e) {
    ref.current.click();
  }
  
  function handleFileChange(e) {
    const file = e.target.files[0];
    setFileToUpload(file);
  }

  async function uploadFile() {
    if (fileToUpload.name === ""){
      return;
    }
    setUploadStatus(true);
    try {
      let key =uuid() + "/" + fileToUpload.name;
      let result = await Storage.put("in/" + key, fileToUpload, {
        progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setUploadProgress(100*progress.loaded/progress.total);
            if (progress.loaded === progress.total) {
              setIsFlashbarDisplayed(true);
              setUploadStatus(false);
              setUploadProgress(0);
              setFileToUpload();
              return;
            }
        },
        errorCallback: (err) => {
            console.error('Unexpected error while uploading', err);
        }
        });
      console.log("Sucess");
      console.log(result);
      getFile("out/" + key);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }


  return (
    <ContentLayout header={<Header variant="h1">Translate your PowerPoint Presentation</Header>}>
      {isFlashbarDisplayed && <Flashbar items={flashbarItems} />}
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {
              uploadStatus
              ? <Button variant="primary" iconName="upload" loading={true}>
                  Uploading file: {uploadProgress.toFixed(0)}%
                </Button>
              : <>
                  <Button variant="link">Cancel</Button>
                  <Button variant="primary" iconName="upload" loading={uploadStatus === "inProgress" && true} onClick={uploadFile}>
                    Upload file
                  </Button>
                </>
            }
          </SpaceBetween>
        }
      >
        <Container header={<Header variant="h2">Translator</Header>}>
          <FormField
            label="Upload a Powerpoint Presentation"
            description="The program will automatically translate all slide notes. This file will be momentarily stored on a S3 bucket, and auto-deleted."
            constraintText="Only PPTX format."
            // errorText={"There was an error, please try again."}
            stretch={true}
            i18nStrings={{ errorIconAriaLabel: 'Error' }}
          >
            <Box variant="p">
              <input ref={ref} type="file" accept={".pptx"} style={{display: "none"}} onChange={handleFileChange} />
              <Button onClick={handleClick}>Choose file</Button>
            </Box>
          </FormField>
          {fileToUpload &&
            <>
              <TextContent>
                <br/>
                <Icon name="status-positive" variant="success" /> 
                <strong> File: {fileToUpload.name}</strong>
                <br/>
                {/* <small>Size: {fileToUpload.size} B</small> */}
              </TextContent>
            </>
          }
        </Container>
      </Form>
    </ContentLayout>
    );
};