const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-west-1:645145ce-f7e5-45b5-a15f-f20c25f2c8c6', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
    //userPoolId: 'XX-XXXX-X_abcd1234', // OPTIONAL - Amazon Cognito User Pool ID for authenticated user access
    //userPoolWebClientId: 'XX-XXXX-X_abcd1234', // OPTIONAL - Amazon Cognito Web Client ID for authenticated user access
  },
  Storage: {
    AWSS3: {
        bucket: 'translationappstack-powerpointfilese6d54cc2-1g9zwrcpso4rh', //REQUIRED -  Amazon S3 bucket name
        region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  }

  ///// To get started with this: https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#manual-setup-import-storage-bucket
};

  


export default amplifyConfig;